'use client'
import useClientRender from '@/util/use-client-render'
import { createContext, Dispatch, SetStateAction } from 'react'
import { useLocalStorage } from 'react-use'

export type VideoContextProps = {
    ccEnabled?: boolean
    setCCEnabled: Dispatch<SetStateAction<boolean | undefined>>
}

export const VideoContext = createContext({
    ccEnabled: false,
    setCCEnabled: () => {},
} as VideoContextProps)

type Props = {
    children: React.ReactNode
}

const VideoContextManager = ({ children }: Props) => {
    const [ccEnabled, setCCEnabled] = useLocalStorage('showCC', false)
    const isClient = useClientRender()
    return (
        <VideoContext.Provider
            value={{
                ccEnabled: isClient ? !!ccEnabled : undefined,
                setCCEnabled,
            }}
        >
            {children}
        </VideoContext.Provider>
    )
}

export default VideoContextManager
